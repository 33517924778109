import React, { useEffect, useState } from 'react';
import { listHomeOwnerDetails } from '../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import { CircularProgress } from '@mui/material';
import ALCard from './ALCard';

function ALContent(props) {
    const [loading, setLoading] = useState(false);
    const [alContent, setAlContent] = useState([]);
    const [filter, setFilter] = useState(null);

    useEffect(() => {
        setLoading(true);
        (async () => {
            const listingDetails = await API.graphql(graphqlOperation(listHomeOwnerDetails, { filter: { verified: { eq: 'active'}, showDetails: { eq: true }}}));
            console.log(listingDetails.data.listHomeOwnerDetails.items);
            setAlContent(listingDetails.data.listHomeOwnerDetails.items);
            setLoading(false);
        })();
    }, [])
    return (
        <div className='card_container'>
            <h1>Active Listings</h1>
            <div className='verification_table'>
                <div className='table_header'>
                    <h3>Address</h3>
                    <h3>Interest Type</h3>
                    <h3>Interest Price</h3>
                    <h3>Photos</h3>
                    <h3>Actions</h3>
                </div>
                {loading ? <div className='center'><CircularProgress /></div> : alContent.length > 0 ? alContent.map(content => <ALCard content={content} />) : <div className='center'><h3>There are currently no active listings</h3></div>}
            </div>
        </div>
    );
}

export default ALContent;