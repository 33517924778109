/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      firstName
      lastName
      email
      phoneNumber
      profileIcon {
        url
        order
        id
        createdAt
        updatedAt
        homeOwnerDetailsPhotosId
        __typename
      }
      type
      tcSigned
      interests {
        nextToken
        __typename
      }
      pushToken
      buyerDetails {
        interestType
        propertyType
        beds
        baths
        minBudget
        maxBudget
        sqft
        preapproved
        preapprovedUntil
        id
        createdAt
        updatedAt
        buyerDetailsUserId
        __typename
      }
      homeOwnerDetails {
        addressOne
        addressTwo
        city
        state
        zip
        lat
        long
        verified
        showDetails
        interestType
        interestPrice
        propertyType
        numberBeds
        numberBaths
        community
        sqft
        id
        createdAt
        updatedAt
        homeOwnerDetailsUserId
        __typename
      }
      realtorDetails {
        zip
        state
        community
        brokerage
        licensedInState
        licenseNumber
        status
        contractTerm
        chargedRate
        subscriptionType
        id
        createdAt
        updatedAt
        realtorDetailsUserId
        __typename
      }
      id
      createdAt
      updatedAt
      userProfileIconId
      userBuyerDetailsId
      userHomeOwnerDetailsId
      userRealtorDetailsId
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        firstName
        lastName
        email
        phoneNumber
        type
        tcSigned
        pushToken
        status
        id
        createdAt
        updatedAt
        userProfileIconId
        userBuyerDetailsId
        userHomeOwnerDetailsId
        userRealtorDetailsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBuyerDetails = /* GraphQL */ `
  query GetBuyerDetails($id: ID!) {
    getBuyerDetails(id: $id) {
      interestType
      propertyType
      beds
      baths
      minBudget
      maxBudget
      sqft
      features {
        pool
        fence
        garage
        garden
        __typename
      }
      documents {
        nextToken
        __typename
      }
      preapproved
      preapprovedUntil
      user {
        firstName
        lastName
        email
        phoneNumber
        type
        tcSigned
        pushToken
        id
        createdAt
        updatedAt
        userProfileIconId
        userBuyerDetailsId
        userHomeOwnerDetailsId
        userRealtorDetailsId
        __typename
      }
      id
      createdAt
      updatedAt
      buyerDetailsUserId
      __typename
    }
  }
`;
export const listBuyerDetails = /* GraphQL */ `
  query ListBuyerDetails(
    $filter: ModelBuyerDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBuyerDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        interestType
        propertyType
        beds
        baths
        minBudget
        maxBudget
        sqft
        preapproved
        preapprovedUntil
        id
        createdAt
        updatedAt
        buyerDetailsUserId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRealtorDetails = /* GraphQL */ `
  query GetRealtorDetails($id: ID!) {
    getRealtorDetails(id: $id) {
      zip
      state
      community
      brokerage
      licensedInState
      licenseNumber
      status
      contractTerm
      chargedRate
      user {
        firstName
        lastName
        email
        phoneNumber
        type
        tcSigned
        pushToken
        id
        createdAt
        updatedAt
        userProfileIconId
        userBuyerDetailsId
        userHomeOwnerDetailsId
        userRealtorDetailsId
        __typename
      }
      subscriptionType
      id
      createdAt
      updatedAt
      realtorDetailsUserId
      __typename
    }
  }
`;
export const listRealtorDetails = /* GraphQL */ `
  query ListRealtorDetails(
    $filter: ModelRealtorDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRealtorDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        zip
        state
        community
        brokerage
        licensedInState
        licenseNumber
        status
        contractTerm
        chargedRate
        subscriptionType
        id
        createdAt
        updatedAt
        realtorDetailsUserId
        user {
          firstName
          lastName
          email
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getHomeOwnerDetails = /* GraphQL */ `
  query GetHomeOwnerDetails($id: ID!) {
    getHomeOwnerDetails(id: $id) {
      addressOne
      addressTwo
      city
      state
      zip
      lat
      long
      documents {
        nextToken
        __typename
      }
      verified
      showDetails
      interestType
      interestPrice
      propertyType
      photos {
        nextToken
        __typename
      }
      numberBeds
      numberBaths
      community
      sqft
      user {
        firstName
        lastName
        email
        phoneNumber
        type
        tcSigned
        pushToken
        id
        createdAt
        updatedAt
        userProfileIconId
        userBuyerDetailsId
        userHomeOwnerDetailsId
        userRealtorDetailsId
        __typename
      }
      id
      createdAt
      updatedAt
      homeOwnerDetailsUserId
      __typename
    }
  }
`;
export const listHomeOwnerDetails = /* GraphQL */ `
  query ListHomeOwnerDetails(
    $filter: ModelHomeOwnerDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHomeOwnerDetails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        addressOne
        addressTwo
        city
        state
        zip
        lat
        long
        verified
        showDetails
        interestType
        interestPrice
        propertyType
        numberBeds
        numberBaths
        community
        sqft
        id
        createdAt
        updatedAt
        documents {
          items {
            id
            name
            uri
          }
        }
        user {
          firstName
          lastName
          email
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInterest = /* GraphQL */ `
  query GetInterest($id: ID!) {
    getInterest(id: $id) {
      numberBeds
      numberBaths
      community
      unit
      minPrice
      maxPrice
      sqft
      status
      addressOne
      city
      state
      zip
      interestType
      propertyType
      user {
        firstName
        lastName
        email
        phoneNumber
        type
        tcSigned
        pushToken
        id
        createdAt
        updatedAt
        userProfileIconId
        userBuyerDetailsId
        userHomeOwnerDetailsId
        userRealtorDetailsId
        __typename
      }
      realtor {
        zip
        state
        community
        brokerage
        licensedInState
        licenseNumber
        status
        contractTerm
        chargedRate
        subscriptionType
        id
        createdAt
        updatedAt
        realtorDetailsUserId
        __typename
      }
      id
      createdAt
      updatedAt
      userInterestsId
      interestRealtorId
      __typename
    }
  }
`;
export const listInterests = /* GraphQL */ `
  query ListInterests(
    $filter: ModelInterestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInterests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        numberBeds
        numberBaths
        community
        unit
        minPrice
        maxPrice
        sqft
        status
        addressOne
        city
        state
        zip
        interestType
        propertyType
        id
        createdAt
        updatedAt
        userInterestsId
        interestRealtorId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPhoto = /* GraphQL */ `
  query GetPhoto($id: ID!) {
    getPhoto(id: $id) {
      url
      order
      id
      createdAt
      updatedAt
      homeOwnerDetailsPhotosId
      __typename
    }
  }
`;
export const listPhotos = /* GraphQL */ `
  query ListPhotos(
    $filter: ModelPhotoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhotos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        url
        order
        id
        createdAt
        updatedAt
        homeOwnerDetailsPhotosId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDocument = /* GraphQL */ `
  query GetDocument($id: ID!) {
    getDocument(id: $id) {
      name
      uri
      id
      createdAt
      updatedAt
      buyerDetailsDocumentsId
      homeOwnerDetailsDocumentsId
      __typename
    }
  }
`;
export const listDocuments = /* GraphQL */ `
  query ListDocuments(
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        name
        uri
        id
        createdAt
        updatedAt
        buyerDetailsDocumentsId
        homeOwnerDetailsDocumentsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChat = /* GraphQL */ `
  query GetChat($id: ID!) {
    getChat(id: $id) {
      messages {
        nextToken
        __typename
      }
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listChats = /* GraphQL */ `
  query ListChats(
    $filter: ModelChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      content
      sender {
        firstName
        lastName
        email
        phoneNumber
        type
        tcSigned
        pushToken
        id
        createdAt
        updatedAt
        userProfileIconId
        userBuyerDetailsId
        userHomeOwnerDetailsId
        userRealtorDetailsId
        __typename
      }
      id
      createdAt
      updatedAt
      chatMessagesId
      messageSenderId
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        content
        id
        createdAt
        updatedAt
        chatMessagesId
        messageSenderId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        firstName
        lastName
        email
        phoneNumber
        type
        tcSigned
        pushToken
        id
        createdAt
        updatedAt
        emailVerified
        userProfileIconId
        userBuyerDetailsId
        userHomeOwnerDetailsId
        userRealtorDetailsId
        realtorDetails {
          subscriptionType
          status
          zip
          state
          community
          brokerage
          licensedInState
          licenseNumber
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const realtorDetailsByZip = /* GraphQL */ `
  query RealtorDetailsByZip(
    $zip: String!
    $sortDirection: ModelSortDirection
    $filter: ModelRealtorDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    realtorDetailsByZip(
      zip: $zip
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        zip
        state
        community
        brokerage
        licensedInState
        licenseNumber
        status
        contractTerm
        chargedRate
        subscriptionType
        id
        createdAt
        updatedAt
        realtorDetailsUserId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const realtorDetailsByState = /* GraphQL */ `
  query RealtorDetailsByState(
    $state: String!
    $sortDirection: ModelSortDirection
    $filter: ModelRealtorDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    realtorDetailsByState(
      state: $state
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        zip
        state
        community
        brokerage
        licensedInState
        licenseNumber
        status
        contractTerm
        chargedRate
        subscriptionType
        id
        createdAt
        updatedAt
        realtorDetailsUserId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const interestByAddress = /* GraphQL */ `
  query InterestByAddress(
    $addressOne: String!
    $sortDirection: ModelSortDirection
    $filter: ModelInterestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    interestByAddress(
      addressOne: $addressOne
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        numberBeds
        numberBaths
        community
        unit
        minPrice
        maxPrice
        sqft
        status
        addressOne
        city
        state
        zip
        interestType
        propertyType
        id
        createdAt
        updatedAt
        userInterestsId
        interestRealtorId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const interestByCity = /* GraphQL */ `
  query InterestByCity(
    $city: String!
    $sortDirection: ModelSortDirection
    $filter: ModelInterestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    interestByCity(
      city: $city
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        numberBeds
        numberBaths
        community
        unit
        minPrice
        maxPrice
        sqft
        status
        addressOne
        city
        state
        zip
        interestType
        propertyType
        id
        createdAt
        updatedAt
        userInterestsId
        interestRealtorId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const interestByZip = /* GraphQL */ `
  query InterestByZip(
    $zip: String!
    $sortDirection: ModelSortDirection
    $filter: ModelInterestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    interestByZip(
      zip: $zip
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        numberBeds
        numberBaths
        community
        unit
        minPrice
        maxPrice
        sqft
        status
        addressOne
        city
        state
        zip
        interestType
        propertyType
        id
        createdAt
        updatedAt
        userInterestsId
        interestRealtorId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
