import React, { useContext } from 'react';
import congrats from '../../assets/congrats_illustration.svg';
import UserContext from '../../context/UserContext';

function RealtorOnboardingSuccess() {
    const { user } = useContext(UserContext);
    console.log(user);
    return (
        <div className='realtor_form'>
            <h1>Congratulations!</h1>
            <img src={congrats} />
            <p>You have successfully completed the realtor application. Our team will review your submission and get back to you within the next 24 hours.</p>
            <button onClick={() => window.location.replace('/dashboard')}>Finish</button>
            <p className='helper_text'>Note: Upon selecting "Finish", you will be taken to the dashboard, where you can monitor any status updates.</p>
        </div>
    );
}

export default RealtorOnboardingSuccess;