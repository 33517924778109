/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      firstName
      lastName
      email
      phoneNumber
      profileIcon {
        url
        order
        id
        createdAt
        updatedAt
        homeOwnerDetailsPhotosId
        __typename
      }
      type
      tcSigned
      interests {
        nextToken
        __typename
      }
      pushToken
      buyerDetails {
        interestType
        propertyType
        beds
        baths
        minBudget
        maxBudget
        sqft
        preapproved
        preapprovedUntil
        id
        createdAt
        updatedAt
        buyerDetailsUserId
        __typename
      }
      homeOwnerDetails {
        addressOne
        addressTwo
        city
        state
        zip
        lat
        long
        verified
        showDetails
        interestType
        interestPrice
        propertyType
        numberBeds
        numberBaths
        community
        sqft
        id
        createdAt
        updatedAt
        homeOwnerDetailsUserId
        __typename
      }
      realtorDetails {
        zip
        state
        community
        brokerage
        licensedInState
        licenseNumber
        status
        contractTerm
        chargedRate
        subscriptionType
        id
        createdAt
        updatedAt
        realtorDetailsUserId
        __typename
      }
      id
      createdAt
      updatedAt
      userProfileIconId
      userBuyerDetailsId
      userHomeOwnerDetailsId
      userRealtorDetailsId
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      firstName
      lastName
      email
      phoneNumber
      profileIcon {
        url
        order
        id
        createdAt
        updatedAt
        homeOwnerDetailsPhotosId
        __typename
      }
      type
      tcSigned
      interests {
        nextToken
        __typename
      }
      pushToken
      buyerDetails {
        interestType
        propertyType
        beds
        baths
        minBudget
        maxBudget
        sqft
        preapproved
        preapprovedUntil
        id
        createdAt
        updatedAt
        buyerDetailsUserId
        __typename
      }
      homeOwnerDetails {
        addressOne
        addressTwo
        city
        state
        zip
        lat
        long
        verified
        showDetails
        interestType
        interestPrice
        propertyType
        numberBeds
        numberBaths
        community
        sqft
        id
        createdAt
        updatedAt
        homeOwnerDetailsUserId
        __typename
      }
      realtorDetails {
        zip
        state
        community
        brokerage
        licensedInState
        licenseNumber
        status
        contractTerm
        chargedRate
        subscriptionType
        id
        createdAt
        updatedAt
        realtorDetailsUserId
        __typename
      }
      id
      createdAt
      updatedAt
      userProfileIconId
      userBuyerDetailsId
      userHomeOwnerDetailsId
      userRealtorDetailsId
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      firstName
      lastName
      email
      phoneNumber
      profileIcon {
        url
        order
        id
        createdAt
        updatedAt
        homeOwnerDetailsPhotosId
        __typename
      }
      type
      tcSigned
      interests {
        nextToken
        __typename
      }
      pushToken
      buyerDetails {
        interestType
        propertyType
        beds
        baths
        minBudget
        maxBudget
        sqft
        preapproved
        preapprovedUntil
        id
        createdAt
        updatedAt
        buyerDetailsUserId
        __typename
      }
      homeOwnerDetails {
        addressOne
        addressTwo
        city
        state
        zip
        lat
        long
        verified
        showDetails
        interestType
        interestPrice
        propertyType
        numberBeds
        numberBaths
        community
        sqft
        id
        createdAt
        updatedAt
        homeOwnerDetailsUserId
        __typename
      }
      realtorDetails {
        zip
        state
        community
        brokerage
        licensedInState
        licenseNumber
        status
        contractTerm
        chargedRate
        subscriptionType
        id
        createdAt
        updatedAt
        realtorDetailsUserId
        __typename
      }
      id
      createdAt
      updatedAt
      userProfileIconId
      userBuyerDetailsId
      userHomeOwnerDetailsId
      userRealtorDetailsId
      __typename
    }
  }
`;
export const createBuyerDetails = /* GraphQL */ `
  mutation CreateBuyerDetails(
    $input: CreateBuyerDetailsInput!
    $condition: ModelBuyerDetailsConditionInput
  ) {
    createBuyerDetails(input: $input, condition: $condition) {
      interestType
      propertyType
      beds
      baths
      minBudget
      maxBudget
      sqft
      features {
        pool
        fence
        garage
        garden
        __typename
      }
      documents {
        nextToken
        __typename
      }
      preapproved
      preapprovedUntil
      user {
        firstName
        lastName
        email
        phoneNumber
        type
        tcSigned
        pushToken
        id
        createdAt
        updatedAt
        userProfileIconId
        userBuyerDetailsId
        userHomeOwnerDetailsId
        userRealtorDetailsId
        __typename
      }
      id
      createdAt
      updatedAt
      buyerDetailsUserId
      __typename
    }
  }
`;
export const updateBuyerDetails = /* GraphQL */ `
  mutation UpdateBuyerDetails(
    $input: UpdateBuyerDetailsInput!
    $condition: ModelBuyerDetailsConditionInput
  ) {
    updateBuyerDetails(input: $input, condition: $condition) {
      interestType
      propertyType
      beds
      baths
      minBudget
      maxBudget
      sqft
      features {
        pool
        fence
        garage
        garden
        __typename
      }
      documents {
        nextToken
        __typename
      }
      preapproved
      preapprovedUntil
      user {
        firstName
        lastName
        email
        phoneNumber
        type
        tcSigned
        pushToken
        id
        createdAt
        updatedAt
        userProfileIconId
        userBuyerDetailsId
        userHomeOwnerDetailsId
        userRealtorDetailsId
        __typename
      }
      id
      createdAt
      updatedAt
      buyerDetailsUserId
      __typename
    }
  }
`;
export const deleteBuyerDetails = /* GraphQL */ `
  mutation DeleteBuyerDetails(
    $input: DeleteBuyerDetailsInput!
    $condition: ModelBuyerDetailsConditionInput
  ) {
    deleteBuyerDetails(input: $input, condition: $condition) {
      interestType
      propertyType
      beds
      baths
      minBudget
      maxBudget
      sqft
      features {
        pool
        fence
        garage
        garden
        __typename
      }
      documents {
        nextToken
        __typename
      }
      preapproved
      preapprovedUntil
      user {
        firstName
        lastName
        email
        phoneNumber
        type
        tcSigned
        pushToken
        id
        createdAt
        updatedAt
        userProfileIconId
        userBuyerDetailsId
        userHomeOwnerDetailsId
        userRealtorDetailsId
        __typename
      }
      id
      createdAt
      updatedAt
      buyerDetailsUserId
      __typename
    }
  }
`;
export const createRealtorDetails = /* GraphQL */ `
  mutation CreateRealtorDetails(
    $input: CreateRealtorDetailsInput!
    $condition: ModelRealtorDetailsConditionInput
  ) {
    createRealtorDetails(input: $input, condition: $condition) {
      zip
      state
      community
      brokerage
      licensedInState
      licenseNumber
      status
      contractTerm
      chargedRate
      user {
        firstName
        lastName
        email
        phoneNumber
        type
        tcSigned
        pushToken
        id
        createdAt
        updatedAt
        userProfileIconId
        userBuyerDetailsId
        userHomeOwnerDetailsId
        userRealtorDetailsId
        __typename
      }
      subscriptionType
      id
      createdAt
      updatedAt
      realtorDetailsUserId
      __typename
    }
  }
`;
export const updateRealtorDetails = /* GraphQL */ `
  mutation UpdateRealtorDetails(
    $input: UpdateRealtorDetailsInput!
    $condition: ModelRealtorDetailsConditionInput
  ) {
    updateRealtorDetails(input: $input, condition: $condition) {
      zip
      state
      community
      brokerage
      licensedInState
      licenseNumber
      status
      contractTerm
      chargedRate
      user {
        firstName
        lastName
        email
        phoneNumber
        type
        tcSigned
        pushToken
        id
        createdAt
        updatedAt
        userProfileIconId
        userBuyerDetailsId
        userHomeOwnerDetailsId
        userRealtorDetailsId
        __typename
      }
      subscriptionType
      id
      createdAt
      updatedAt
      realtorDetailsUserId
      __typename
    }
  }
`;
export const deleteRealtorDetails = /* GraphQL */ `
  mutation DeleteRealtorDetails(
    $input: DeleteRealtorDetailsInput!
    $condition: ModelRealtorDetailsConditionInput
  ) {
    deleteRealtorDetails(input: $input, condition: $condition) {
      zip
      state
      community
      brokerage
      licensedInState
      licenseNumber
      status
      contractTerm
      chargedRate
      user {
        firstName
        lastName
        email
        phoneNumber
        type
        tcSigned
        pushToken
        id
        createdAt
        updatedAt
        userProfileIconId
        userBuyerDetailsId
        userHomeOwnerDetailsId
        userRealtorDetailsId
        __typename
      }
      subscriptionType
      id
      createdAt
      updatedAt
      realtorDetailsUserId
      __typename
    }
  }
`;
export const createHomeOwnerDetails = /* GraphQL */ `
  mutation CreateHomeOwnerDetails(
    $input: CreateHomeOwnerDetailsInput!
    $condition: ModelHomeOwnerDetailsConditionInput
  ) {
    createHomeOwnerDetails(input: $input, condition: $condition) {
      addressOne
      addressTwo
      city
      state
      zip
      lat
      long
      documents {
        nextToken
        __typename
      }
      verified
      showDetails
      interestType
      interestPrice
      propertyType
      photos {
        nextToken
        __typename
      }
      numberBeds
      numberBaths
      community
      sqft
      user {
        firstName
        lastName
        email
        phoneNumber
        type
        tcSigned
        pushToken
        id
        createdAt
        updatedAt
        userProfileIconId
        userBuyerDetailsId
        userHomeOwnerDetailsId
        userRealtorDetailsId
        __typename
      }
      id
      createdAt
      updatedAt
      homeOwnerDetailsUserId
      __typename
    }
  }
`;
export const updateHomeOwnerDetails = /* GraphQL */ `
  mutation UpdateHomeOwnerDetails(
    $input: UpdateHomeOwnerDetailsInput!
    $condition: ModelHomeOwnerDetailsConditionInput
  ) {
    updateHomeOwnerDetails(input: $input, condition: $condition) {
      addressOne
      addressTwo
      city
      state
      zip
      lat
      long
      documents {
        nextToken
        __typename
      }
      verified
      showDetails
      interestType
      interestPrice
      propertyType
      photos {
        nextToken
        __typename
      }
      numberBeds
      numberBaths
      community
      sqft
      user {
        firstName
        lastName
        email
        phoneNumber
        type
        tcSigned
        pushToken
        id
        createdAt
        updatedAt
        userProfileIconId
        userBuyerDetailsId
        userHomeOwnerDetailsId
        userRealtorDetailsId
        __typename
      }
      id
      createdAt
      updatedAt
      homeOwnerDetailsUserId
      __typename
    }
  }
`;
export const deleteHomeOwnerDetails = /* GraphQL */ `
  mutation DeleteHomeOwnerDetails(
    $input: DeleteHomeOwnerDetailsInput!
    $condition: ModelHomeOwnerDetailsConditionInput
  ) {
    deleteHomeOwnerDetails(input: $input, condition: $condition) {
      addressOne
      addressTwo
      city
      state
      zip
      lat
      long
      documents {
        nextToken
        __typename
      }
      verified
      showDetails
      interestType
      interestPrice
      propertyType
      photos {
        nextToken
        __typename
      }
      numberBeds
      numberBaths
      community
      sqft
      user {
        firstName
        lastName
        email
        phoneNumber
        type
        tcSigned
        pushToken
        id
        createdAt
        updatedAt
        userProfileIconId
        userBuyerDetailsId
        userHomeOwnerDetailsId
        userRealtorDetailsId
        __typename
      }
      id
      createdAt
      updatedAt
      homeOwnerDetailsUserId
      __typename
    }
  }
`;
export const createInterest = /* GraphQL */ `
  mutation CreateInterest(
    $input: CreateInterestInput!
    $condition: ModelInterestConditionInput
  ) {
    createInterest(input: $input, condition: $condition) {
      numberBeds
      numberBaths
      community
      unit
      minPrice
      maxPrice
      sqft
      status
      addressOne
      city
      state
      zip
      interestType
      propertyType
      user {
        firstName
        lastName
        email
        phoneNumber
        type
        tcSigned
        pushToken
        id
        createdAt
        updatedAt
        userProfileIconId
        userBuyerDetailsId
        userHomeOwnerDetailsId
        userRealtorDetailsId
        __typename
      }
      realtor {
        zip
        state
        community
        brokerage
        licensedInState
        licenseNumber
        status
        contractTerm
        chargedRate
        subscriptionType
        id
        createdAt
        updatedAt
        realtorDetailsUserId
        __typename
      }
      id
      createdAt
      updatedAt
      userInterestsId
      interestRealtorId
      __typename
    }
  }
`;
export const updateInterest = /* GraphQL */ `
  mutation UpdateInterest(
    $input: UpdateInterestInput!
    $condition: ModelInterestConditionInput
  ) {
    updateInterest(input: $input, condition: $condition) {
      numberBeds
      numberBaths
      community
      unit
      minPrice
      maxPrice
      sqft
      status
      addressOne
      city
      state
      zip
      interestType
      propertyType
      user {
        firstName
        lastName
        email
        phoneNumber
        type
        tcSigned
        pushToken
        id
        createdAt
        updatedAt
        userProfileIconId
        userBuyerDetailsId
        userHomeOwnerDetailsId
        userRealtorDetailsId
        __typename
      }
      realtor {
        zip
        state
        community
        brokerage
        licensedInState
        licenseNumber
        status
        contractTerm
        chargedRate
        subscriptionType
        id
        createdAt
        updatedAt
        realtorDetailsUserId
        __typename
      }
      id
      createdAt
      updatedAt
      userInterestsId
      interestRealtorId
      __typename
    }
  }
`;
export const deleteInterest = /* GraphQL */ `
  mutation DeleteInterest(
    $input: DeleteInterestInput!
    $condition: ModelInterestConditionInput
  ) {
    deleteInterest(input: $input, condition: $condition) {
      numberBeds
      numberBaths
      community
      unit
      minPrice
      maxPrice
      sqft
      status
      addressOne
      city
      state
      zip
      interestType
      propertyType
      user {
        firstName
        lastName
        email
        phoneNumber
        type
        tcSigned
        pushToken
        id
        createdAt
        updatedAt
        userProfileIconId
        userBuyerDetailsId
        userHomeOwnerDetailsId
        userRealtorDetailsId
        __typename
      }
      realtor {
        zip
        state
        community
        brokerage
        licensedInState
        licenseNumber
        status
        contractTerm
        chargedRate
        subscriptionType
        id
        createdAt
        updatedAt
        realtorDetailsUserId
        __typename
      }
      id
      createdAt
      updatedAt
      userInterestsId
      interestRealtorId
      __typename
    }
  }
`;
export const createPhoto = /* GraphQL */ `
  mutation CreatePhoto(
    $input: CreatePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    createPhoto(input: $input, condition: $condition) {
      url
      order
      id
      createdAt
      updatedAt
      homeOwnerDetailsPhotosId
      __typename
    }
  }
`;
export const updatePhoto = /* GraphQL */ `
  mutation UpdatePhoto(
    $input: UpdatePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    updatePhoto(input: $input, condition: $condition) {
      url
      order
      id
      createdAt
      updatedAt
      homeOwnerDetailsPhotosId
      __typename
    }
  }
`;
export const deletePhoto = /* GraphQL */ `
  mutation DeletePhoto(
    $input: DeletePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    deletePhoto(input: $input, condition: $condition) {
      url
      order
      id
      createdAt
      updatedAt
      homeOwnerDetailsPhotosId
      __typename
    }
  }
`;
export const createDocument = /* GraphQL */ `
  mutation CreateDocument(
    $input: CreateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    createDocument(input: $input, condition: $condition) {
      name
      uri
      id
      createdAt
      updatedAt
      buyerDetailsDocumentsId
      homeOwnerDetailsDocumentsId
      __typename
    }
  }
`;
export const updateDocument = /* GraphQL */ `
  mutation UpdateDocument(
    $input: UpdateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    updateDocument(input: $input, condition: $condition) {
      name
      uri
      id
      createdAt
      updatedAt
      buyerDetailsDocumentsId
      homeOwnerDetailsDocumentsId
      __typename
    }
  }
`;
export const deleteDocument = /* GraphQL */ `
  mutation DeleteDocument(
    $input: DeleteDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    deleteDocument(input: $input, condition: $condition) {
      name
      uri
      id
      createdAt
      updatedAt
      buyerDetailsDocumentsId
      homeOwnerDetailsDocumentsId
      __typename
    }
  }
`;
export const createChat = /* GraphQL */ `
  mutation CreateChat(
    $input: CreateChatInput!
    $condition: ModelChatConditionInput
  ) {
    createChat(input: $input, condition: $condition) {
      messages {
        nextToken
        __typename
      }
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateChat = /* GraphQL */ `
  mutation UpdateChat(
    $input: UpdateChatInput!
    $condition: ModelChatConditionInput
  ) {
    updateChat(input: $input, condition: $condition) {
      messages {
        nextToken
        __typename
      }
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteChat = /* GraphQL */ `
  mutation DeleteChat(
    $input: DeleteChatInput!
    $condition: ModelChatConditionInput
  ) {
    deleteChat(input: $input, condition: $condition) {
      messages {
        nextToken
        __typename
      }
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      content
      sender {
        firstName
        lastName
        email
        phoneNumber
        type
        tcSigned
        pushToken
        id
        createdAt
        updatedAt
        userProfileIconId
        userBuyerDetailsId
        userHomeOwnerDetailsId
        userRealtorDetailsId
        __typename
      }
      id
      createdAt
      updatedAt
      chatMessagesId
      messageSenderId
      __typename
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      content
      sender {
        firstName
        lastName
        email
        phoneNumber
        type
        tcSigned
        pushToken
        id
        createdAt
        updatedAt
        userProfileIconId
        userBuyerDetailsId
        userHomeOwnerDetailsId
        userRealtorDetailsId
        __typename
      }
      id
      createdAt
      updatedAt
      chatMessagesId
      messageSenderId
      __typename
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      content
      sender {
        firstName
        lastName
        email
        phoneNumber
        type
        tcSigned
        pushToken
        id
        createdAt
        updatedAt
        userProfileIconId
        userBuyerDetailsId
        userHomeOwnerDetailsId
        userRealtorDetailsId
        __typename
      }
      id
      createdAt
      updatedAt
      chatMessagesId
      messageSenderId
      __typename
    }
  }
`;
