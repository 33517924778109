import React from 'react';

function ErrorPath(props) {
    return (
        <div>
            <h1>404 Not Found</h1>
        </div>
    );
}

export default ErrorPath;