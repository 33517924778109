import React from 'react';
import '../stylesheets/landing.css';
import logo from '../assets/hint_logo_white.png';
import downArrow from '../assets/double_down_arrow.png';
import iconOne from '../assets/iconOne.svg';
import iconTwo from '../assets/iconTwo.svg';
import iconThree from '../assets/iconThree.svg';
import insta from '../assets/insta.svg';
import twitter from '../assets/twitter.svg';
import fb from '../assets/fb.svg';
import { useMediaQuery } from '@mui/material';

/* 

    Page description:
    From this page, a user has the ability to scroll through the website and view information about HINT, or navigate to the login page.
    The only other outlets from this page include the social media buttons for now.

    Future adds:
    1. Ability to provide a mobile download link (should be able to check if user is on mobile or desktop - if desktop, use QR; else use app store link redirect)

 */
function Landing(props) {
  const matches = useMediaQuery('(min-width:600px)');

    return (
        <div className='landing_container'>
            <div className='first_section'>
                <div className='header'>
                    <div className='left_header_container'>
                        <img src={logo} />
                        <div className='flex_column'>
                            <h1>HINT</h1>
                            <h3>HOUSING INTELLIGENCE</h3>
                        </div>
                    </div>
                    <div className='header_button_container'>
                        <a href='/dashboard'><button>LOGIN</button></a>
                    </div>
                </div>
                <div className='bottom_container'>
                    <h1>Discover your dream property today.</h1>
                    <div className='learn_more'>
                        <p>Learn more</p>
                        <img src={downArrow} />
                    </div>
                </div>
            </div>
            <div className='thin_section'>
                <h1>The Opportunity</h1>
                <p>Empower prospective home buyers and sellers by creating an open interest platform for off-market properties: accelerating the time to find your perfect home and eliminating the selling agent and associated fees.</p>
            </div>
            <div className='third_section'>
            </div>
            <div className='thin_section'>
                <h1>Our Vision</h1>
                <div className='iconHolder'>
                    <img src={iconOne} />
                    <p>Connect Property Owners with Prospective Buyers & Tenants</p>
                </div>
                <div className='iconHolder'>
                    <img src={iconTwo} />
                    <p>Maximize Our User’s Savings.</p>
                </div>
                <div className='iconHolder'>
                    <img src={iconThree} />
                    <p>Assist Users in Navigating the Real Estate Process</p>
                </div>
            </div>
            <div className='fourth_section'>
            </div>
            <div className='fifth_section'>
            </div>
            <div className='footer'>
                <div className='left_footer_container'>
                    <img src={logo} />
                    <p>Connect with us</p>
                    <div className='socials_container'>
                        <img src={insta} />
                        <img src={twitter} />
                        <img src={fb} />
                    </div>
                    <p>info@houseint.co</p>
                </div>
                <div className='right_footer_container'>
                    <h3>Connect with us today to start your search for your dream property!</h3>
                    <p>Our locations</p>
                    <div className='locations'>
                        <p>District of Columbia</p>
                        <p>South Carolina</p>
                        <p>Florida</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Landing;