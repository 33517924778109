import React, { useContext, useState } from 'react';
import UserContext from '../../context/UserContext';
import security from '../../assets/security_illustration.svg';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import AlertCard from '../AlertCard';
import { states } from '../../data/states';
import { createRealtorDetails, updateUser } from '../../graphql/mutations';

function RealtorDetails() {
    const { user, setUser } = useContext(UserContext);
    const [open, setOpen] = useState(false);
    const [basicdetailsForm, setBasicDetailsForm] = useState({ licenseState: "", licenseNumber: "", brokerage: ""})
    const [submitting, setSubmitting] = useState(false);

    const handleBack = async (e) => {
        await Auth.signOut();
    }

    const handleSelect = (e) => {
        console.log(e);
        setBasicDetailsForm({...basicdetailsForm, licenseState: e.target.value});
    }

    const handleChange = (e) => setBasicDetailsForm({...basicdetailsForm, [e.target.name]: e.target.value});
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        if (basicdetailsForm.licenseState === "") {
            alert("Please select the state you are licensed in.");
            setSubmitting(false);
            return;
        }
        try {
            const rdInput = {
                brokerage: basicdetailsForm.brokerage,
                state: basicdetailsForm.licenseState,
                licenseNumber: basicdetailsForm.licenseNumber,
                status: "pending",
                realtorDetailsUserId: user.id
            }
            console.log(rdInput);
            const realtorDetails = await API.graphql(graphqlOperation(createRealtorDetails, { input: rdInput }))
            const userInput = {
                id: user.id,
                userRealtorDetailsId: realtorDetails.data.createRealtorDetails.id,
                type: "realtor"
            }
            await API.graphql(graphqlOperation(updateUser, { input: userInput}))
            setUser({...user, userRealtorDetailsId: realtorDetails.data.createRealtorDetails.id, type: "realtor", realtorDetails: { subscriptionType: null }});
            setSubmitting(false);
        } catch (err) {
            alert(JSON.stringify(err));
            setSubmitting(false);
        }
    }
    return (
        <form className='realtor_form' onSubmit={handleSubmit}>
            <h1>Hello, { user && user.firstName}</h1>
            <img src={security} />
            <p className='width_90 margin_bottom'>Next, give us some details that we can use to verify your information.</p>
            <div className='width_90 flex_column'>
                <p>State of License:</p>
                <select onChange={handleSelect}>
                    <option value={""}>Please select a state</option>
                    {states.map(state => <option key={state} value={state}>{state}</option>)}
                </select>
            </div>
            <div className='width_90 flex_column'>
                <p>License Number:</p>
                <input name={"licenseNumber"} placeholder={"12345"} value={basicdetailsForm.licenseNumber} onChange={handleChange} />
            </div>
            <div className='width_90 flex_column'>
                <p>Brokerage:</p>
                <input name={"brokerage"} placeholder={'Keller Williams, Berkshire Hathaway, etc.'} value={basicdetailsForm.brokerage} onChange={handleChange} />
            </div>
            <button type='submit' disabled={submitting}>{submitting ? 'Submitting...' : 'Submit'}</button>
            <button id="back_button" onClick={(e) => { e.preventDefault(); setOpen(true)}}>{'<'} Back</button>
            <AlertCard 
                title={"Are you sure you want to go back?"} 
                text={"By going back, you will be signing out, and will need to sign back in to continue."}
                openState={{open, setOpen}}
                submitBtn={"Go back"}
                handleSubmit={handleBack}
            />
        </form>
    );
}

export default RealtorDetails;