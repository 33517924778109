import React, { useContext, useState } from 'react';
import UserContext from '../../context/UserContext';
import illustration from '../../assets/welcome_illustration.svg';
import { Auth } from 'aws-amplify';
import AlertCard from '../AlertCard';

function RealtorWelcome({ setState }) {
    const { user, setUser } = useContext(UserContext);
    const [open, setOpen] = useState(false);

    const handleBack = async (e) => {
        await Auth.signOut();
    }

    return (
        <div className='realtor_form'>
            <h1>Welcome to HINT!</h1>
            <img src={illustration} />
            <p>As a HINT realtor, you are given the opportunity to work with connected buyers and property owners to help them navigate the real estate journey.<br/><br />To start your journey, please go through our onboarding process below.</p>
            <button onClick={() => setState("start")}>Continue</button>
            {!user ? <a id="realtor_btn" href='/dashboard'>Return to dashboard</a> : <a id="realtor_btn" href='/dashboard' onClick={(e) => { e.preventDefault(); setOpen(true)}}>Return to dashboard</a>}
            <AlertCard
                title={"Are you sure you want to go back?"} 
                text={"By going back, you will be signing out, and will need to sign back in to continue."}
                openState={{open, setOpen}}
                submitBtn={"Go back"}
                handleSubmit={handleBack}
            />
        </div>
    );
}

export default RealtorWelcome;