import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import logo from '../assets/hint_logo.png';

function SignIn() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const handleSignIn = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            await Auth.signIn(email, password);
            setSubmitting(false);
        } catch (e) {
            alert(e);
            setSubmitting(false);
        }
    }

    return (
        <div className='form_container'>
            <form className='sign_in_form' onSubmit={handleSignIn}>
                <img src={logo} />
                <input placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)}/>
                <input placeholder='Password' type="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
                <button type='submit' disabled={submitting}>{submitting ? 'Signing In...' : 'Sign In'}</button>
                <a id="realtor_btn" href='/realtor'>Interested in becoming a HINT realtor?</a>
                <a id="realtor_btn" href='/'>Return to landing</a>
            </form>
        </div>
    );
}

export default SignIn;