import React, { useContext } from 'react';
import UserContext from '../../context/UserContext';
import waiting from '../../assets/waiting_illustration.svg';

function RealtorHomePage(props) {
    const { user } = useContext(UserContext);

    return (
        <div className='content_container'>
            <div className='info_header'>
                <div>
                    <h1>Welcome, {user.firstName}</h1>
                    <h2>Status: {user.realtorDetails.status}</h2>
                </div>
                <div>
                    <p><strong>State:</strong> {user.realtorDetails.state}</p>
                    <p><strong>License #:</strong> {user.realtorDetails.licenseNumber}</p>
                    <p><strong>Brokerage:</strong> {user.realtorDetails.brokerage}</p>
                </div>
            </div>
            <div className='info_container'>
                <div className='realtor_info_content'>
                    {user.realtorDetails.status === "pending" ? 
                        <div className='flex_column'>
                            <p><em>Your status is currently pending. The team will get back to you as quickly as possible.</em></p>
                            <img src={waiting} />
                        </div> :
                        <div className='details_grid'>
                            <h1>Details</h1>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default RealtorHomePage;