import React from 'react';

function MapContent(props) {
    return (
        <div>
            <h1>Map content</h1>
        </div>
    );
}

export default MapContent;