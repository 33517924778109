import React, { useContext, useState } from 'react';
import authentication from '../../assets/auth_illustration.svg';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { createUser } from '../../graphql/mutations';
import UserContext from '../../context/UserContext';

function RealtorAuth({ setState, data }) {
    const { setUser } = useContext(UserContext);
    const [onboardingForm, setOnboardingForm] = useState({ firstName: "", lastName: "", email: "", phoneNumber: "", password: "", confirmPass: ""})
    const [submitting, setSubmitting] = useState(false);
    const [renderedForm, setRenderedForm] = useState("signIn");

    const handleSignUp = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            if (onboardingForm.firstName === "") throw {message: "First name required"};
            if (onboardingForm.lastName === "") throw {message: "Last name required"};
            if (onboardingForm.email === "") throw {message: "Email required"};
            if (onboardingForm.phone === "") throw {message: "Phone number required"};
            if (onboardingForm.password === "") throw {message: "Password required"};
            if (onboardingForm.phoneNumber.length !== 10) throw {message: "Phone number must be valid"}
            if (onboardingForm.confirmPass !== onboardingForm.password) throw {message: 'Passwords don\'t match. Please try again'};

            await Auth.signUp({
                username: onboardingForm.email.trim(), 
                password: onboardingForm.password.trim(), 
                attributes: {
                    given_name: onboardingForm.firstName.trim(),
                    family_name: onboardingForm.lastName.trim(),
                },
                autoSignIn: {
                    enabled: true
                }
            });
            const input = {
                firstName: onboardingForm.firstName.trim(),
                lastName: onboardingForm.lastName.trim(),
                email: onboardingForm.email.trim(),
                type: "buyer",
                tcSigned: false,
                emailVerified: false,
                phoneNumber: onboardingForm.phoneNumber.trim(),
                status: "active"
            }
            const newUser = await API.graphql(graphqlOperation(createUser, { input: input }))
            setUser(newUser.data.createUser)
            setSubmitting(false);
        } catch (e) {
            alert(e.message || JSON.stringify(e));
            setSubmitting(false);
        }
    }

    const handleSignIn = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            await Auth.signIn(onboardingForm.email, onboardingForm.password);
            setSubmitting(false);
        } catch (e) {
            alert(e.message);
            setSubmitting(false);
        }
    }

    const clearFields = () => {
        setOnboardingForm({firstName: "", lastName: "", email: "", phoneNumber: "", password: "", confirmPass: ""})
    }

    return (
        <form className='sign_up_form' onSubmit={renderedForm === "signIn" ? handleSignIn : handleSignUp}>
            <h1>{renderedForm === "signIn" ? "Access Account" : "Create Account"}</h1>
            <img src={authentication} />
            <p className='width_90 margin_bottom'>To start, please sign into your account or create a new one.</p>
            {renderedForm === "signIn" ? 
            <>
                <input placeholder='Email' value={onboardingForm.email} onChange={(e) => setOnboardingForm({...onboardingForm, email: e.target.value})}/>
                <input placeholder='Password' type="password" value={onboardingForm.password} onChange={(e) => setOnboardingForm({...onboardingForm, password: e.target.value})}/>
                <button type='submit' disabled={submitting}>{submitting ? 'Signing In...' : 'Sign In'}</button>
                <p className='helper_text'>Note: If you have created an account on the mobile app, you can use the same credentials here</p>
                <button id="realtor_btn" onClick={(e)=>{e.preventDefault(); setRenderedForm("signUp"); clearFields()}}>Create an account</button>
            </> :
            <>
                <input placeholder='First Name' value={onboardingForm.firstName} onChange={(e) => setOnboardingForm({...onboardingForm, firstName: e.target.value})}/>
                <input placeholder='Last Name' value={onboardingForm.lastName} onChange={(e) => setOnboardingForm({...onboardingForm, lastName: e.target.value})}/>
                <input placeholder='Email' value={onboardingForm.email} onChange={(e) => setOnboardingForm({...onboardingForm, email: e.target.value})}/>
                <input placeholder='Phone Number' value={onboardingForm.phoneNumber} onChange={(e) => setOnboardingForm({...onboardingForm, phoneNumber: e.target.value})}/>
                <p className='helper_text'>Note: Please use a 10-digit number without paranthesis or dashes.</p>
                <input placeholder='Password' type="password" value={onboardingForm.password} onChange={(e) => setOnboardingForm({...onboardingForm, password: e.target.value})}/>
                <input placeholder='Confirm Password' type="password" value={onboardingForm.confirmPass} onChange={(e) => setOnboardingForm({...onboardingForm, confirmPass: e.target.value})}/>
                <button type='submit' disabled={submitting}>{submitting ? 'Signing Up...' : 'Sign Up'}</button>
                <p className='helper_text'>Note: Upon signing up, you will be able to access the mobile app with the same credentials</p>
                <button id="realtor_btn" onClick={(e)=>{e.preventDefault(); setRenderedForm("signIn"); clearFields()}}>Sign in to an account</button>
            </>}
            <button id="back_button" onClick={(e)=>{ e.preventDefault(); setState("welcome")}}>{'<'} Back</button>
        </form>
    );
}

export default RealtorAuth;