import React, { useContext, useEffect, useState } from 'react';
import UserContext from '../../context/UserContext';
import { API, graphqlOperation } from 'aws-amplify';
import { listUsers } from '../../graphql/queries';

function AdminMainPage(props) {
    const { user } = useContext(UserContext);
    const [users, setUsers] = useState(0);
    useEffect(() => {
        (async () => {
            const users = await API.graphql(graphqlOperation(listUsers, { filter: { status: { eq: "active"}}}));
            setUsers(users.data.listUsers)
        })();
    }, [])
    return (
        <div className='card_container'>
            <div className='info_header'>
                <div>
                    <h1>Welcome, {user.firstName}</h1>
                    <p></p>
                </div>
            </div>
            <div className='info_container'>
                <div className='details_grid'>
                    
                </div>
            </div>        
        </div>
    );
}

export default AdminMainPage;