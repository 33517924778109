import React, { useState } from 'react';

function HOVCard({ content, convertDocs }) {
    
    return (
        <div className='card_holder'>
            <p>{content.user.firstName + " " + content.user.lastName}</p>
            <p>{content.user.email}</p>
            <p>{content.addressOne} - {content.zip}</p>
            <button onClick={() => convertDocs(content.documents.items)}>Show Documents</button>
            <div>
                <button>Approve</button>
                <button>Reject</button>
            </div>
        </div>
    );
}

export default HOVCard;