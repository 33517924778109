import React, { useContext, useState } from 'react';
import UserContext from '../context/UserContext';
import logo from '../assets/hint_logo_white.png'
import { Auth } from 'aws-amplify';
import RealtorHomePage from '../components/realtorDashboard/RealtorHomePage';

function RealtorDashboard(props) {
    const { user, setUser } = useContext(UserContext);
    const [content, setContent] = useState("homepage")

    return (
        <div className='realtor_dash_container'>
            <div className='nav_container'>
                <div className='logo_holder'>
                    <img src={logo} />
                    <div>
                        <h3>HINT</h3>
                        <p>HOUSING INTELLIGENCE</p>
                    </div>
                </div>
                <button onClick={() => setContent("homepage")}>Home</button>
                <button onClick={() => setContent("connections")}>Connections</button>
                <button onClick={() => Auth.signOut()}>Sign Out</button>
            </div>
            <div className='content_container'>
                {
                    content === "homepage" ? <RealtorHomePage /> : 
                    <h1>Connections</h1>
                }
            </div>
        </div>
    );
}

export default RealtorDashboard;