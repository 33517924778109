import React from 'react';

function RVCard({ content }) {
    return (
        <div className='card_holder'>
            <p>{content.user.firstName + " " + content.user.lastName}</p>
            <p>{content.user.email}</p>
            <p>{content.state}</p>
            <p>{content.licenseNumber}</p>
            <div>
                <button>Approve</button>
                <button>Reject</button>
            </div>
        </div>
    );
}

export default RVCard;