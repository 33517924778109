import React, { useContext, useState } from 'react';
import '../stylesheets/realtor.css';
import RealtorWelcome from '../components/realtorOnboarding/RealtorWelcome';
import RealtorDetails from '../components/realtorOnboarding/RealtorDetails';
import RealtorConfirmEmail from '../components/realtorOnboarding/RealtorConfirmEmail';
import RealtorAuth from '../components/realtorOnboarding/RealtorAuth';
import RealtorOnboardingSuccess from '../components/realtorOnboarding/RealtorOnboardingSuccess';
import RealtorPayment from '../components/realtorOnboarding/RealtorPayment';
import UserContext from '../context/UserContext';
import TermsConditions from '../components/realtorOnboarding/TermsConditions';

function RealtorOnboarding() {
    const { user } = useContext(UserContext);
    const [state, setState] = useState("welcome");

    return (
        <div className='onboarding_container'>
            {
                state === "welcome" ? <RealtorWelcome setState={setState} /> :
                !user ?
                     <RealtorAuth setState={setState} /> :
                !user.emailVerified ? <RealtorConfirmEmail /> :
                !user.tcSigned ? <TermsConditions /> :
                !user.userRealtorDetailsId ? <RealtorDetails /> :
                // !user.realtorDetails.subscriptionType ? <RealtorPayment /> :
                <RealtorOnboardingSuccess />
            }
        </div>
    );
}

export default RealtorOnboarding;