import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import React from 'react';

function ModalDocViewer({ docs, setDocs }) {
    console.log(docs);
    return (
        <div className='modal_container'>
            <div className='modal_content'>
                <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} />
                <button onClick={() => setDocs(null)}>Close</button>
            </div>
        </div>
    );
}

export default ModalDocViewer;