import { API, graphqlOperation } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { listHomeOwnerDetails } from '../../graphql/queries';
import HOVCard from './HOVCard';
import { CircularProgress, TablePagination } from '@mui/material';
import ModalDocViewer from './ModalDocViewer';

function HOVContent(props) {
    const [loading, setLoading] = useState(false);
    const [hovContent, setHovContent] = useState([]);
    const [docs, setDocs] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };

    const convertDocs = async (data) => {
        const converted = [];
        const mapping = data.map(item => {
            console.log(item);
            const temp = {
                uri: "https://hintmobile0c6b620407bd4c5e988ac97b1fd35f0b124227-dev.s3.amazonaws.com/public/" + item.uri,
                fileName: item.name
            };
            converted.push(temp);
        });
        await Promise.all(mapping);
        setDocs(converted);
    }

    useEffect(() => {
        setLoading(true);
        (async () => {
            const pendingDetails = await API.graphql(graphqlOperation(listHomeOwnerDetails, { filter: { verified: { eq: 'pending'}}, limit: rowsPerPage }));
            console.log(pendingDetails.data.listHomeOwnerDetails.items);
            setHovContent(pendingDetails.data.listHomeOwnerDetails.items);
            setLoading(false);
        })();
    }, [])

    return (
        <div className='card_container'>
            <h1>Home Owner Verification</h1>
            <div className='verification_table'>
                <div className='table_header'>
                    <h3>Name</h3>
                    <h3>Email</h3>
                    <h3>Address</h3>
                    <h3>Documents</h3>
                    <h3>Verify</h3>
                </div>
                {
                    loading ? 
                        <div className='center'><CircularProgress /></div> : 
                        hovContent.length > 0 ? 
                            docs ? 
                                <ModalDocViewer docs={docs} setDocs={setDocs} /> : 
                                <div className='card_list_view'>
                                    {hovContent.map(content => <HOVCard key={content.id} content={content} convertDocs={convertDocs} />)}
                                </div>
                        : <div className='center'><h3>There are currently no pending verifications</h3></div>
                }
                <div className='pagination_holder'>
                    <TablePagination
                        component="div"
                        count={hovContent.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            </div>
            
        </div>
    );
}

export default HOVContent;