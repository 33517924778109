import React, { useContext, useState } from 'react';
import logo from '../../assets/hint_logo.png';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { updateUser } from '../../graphql/mutations';
import UserContext from '../../context/UserContext';
import AlertCard from '../AlertCard';

function RealtorConfirmEmail() {
    const  { user, setUser } = useContext(UserContext);
    const [confirmationCode, setConfirmationCode] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const [open, setOpen] = useState(false);

    const handleBack = async (e) => {
        await Auth.signOut();
    }

    const handleConfirmEmail = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            await Auth.confirmSignUp(user.email, confirmationCode);
            await API.graphql(graphqlOperation(updateUser, { input: { id: user.id, emailVerified: true }}));
            setUser({...user, emailVerified: true});
        } catch (err) {
            alert(err);
            setSubmitting(false);
        }
    }
    return (
        <>
            <form className='sign_up_form' onSubmit={handleConfirmEmail}>
                <img src={logo} />
                <input placeholder='Confirmation Code' value={confirmationCode} onChange={(e) => setConfirmationCode(e.target.value)}/>
                <button type='submit' disabled={submitting}>{submitting ? 'Confirming...' : 'Confirm'}</button>
                <button id='realtor_btn' onClick={(e) => {e.preventDefault(); Auth.resendSignUp(user.email); alert("Code resent to "+user.email)}}>Send another code</button>
                <button id="back_button" onClick={(e) => { e.preventDefault(); setOpen(true)}}>{'<'} Back</button>
            </form>
            <AlertCard
                title={"Are you sure you want to go back?"} 
                text={"By going back, you will be signing out, and will need to sign back in to continue."}
                openState={{open, setOpen}}
                submitBtn={"Go back"}
                handleSubmit={handleBack}
            />
        </>
    );
}

export default RealtorConfirmEmail;