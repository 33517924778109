import React from 'react';
import logo from '../../assets/hint_logo_white.png'
import { Auth } from 'aws-amplify';

function NavBar({ content, setContent }) {
    return (
        <div className='nav_container'>
            <div className='logo_holder'>
                <img src={logo} />
                <div>
                    <h3>HINT</h3>
                    <p>HOUSING INTELLIGENCE</p>
                </div>
            </div>
            <button style={{ background: content === "main" ? "white" : null }} onClick={() => setContent("main")}>Main Page</button>
            <button style={{ background: content === "homeOwnerVerification" ? "white" : null }} onClick={() => setContent("homeOwnerVerification")}>Home Owner Verification</button>
            <button style={{ background: content === "realtorVerification" ? "white" : null }} onClick={() => setContent("realtorVerification")}>Realtor Verification</button>
            <button style={{ background: content === "activeListings" ? "white" : null }} onClick={() => setContent("activeListings")}>Active Listings</button>
            <button style={{ background: content === "map" ? "white" : null }} onClick={() => setContent("map")}>Map</button>
            <button onClick={() => Auth.signOut()}>Sign Out</button>
        </div>
    );
}

export default NavBar;