import React, { useContext, useEffect, useState } from 'react';
import UserContext from '../context/UserContext';
import SignIn from '../components/SignIn';
import '../stylesheets/dashboard.css';
import { Auth } from 'aws-amplify';
import RVContent from '../components/dashboard/RVContent';
import ALContent from '../components/dashboard/ALContent';
import MapContent from '../components/dashboard/MapContent';
import RealtorOnboarding from './RealtorOnboarding';
import HOVContent from '../components/dashboard/HOVContent';
import NavBar from '../components/dashboard/NavBar';
import RealtorDashboard from './RealtorDashboard';
import AdminMainPage from '../components/dashboard/AdminMainPage';

function Dashboard(props) {
    const { user, setUser } = useContext(UserContext);
    const [content, setContent] = useState("main")
    return (
        <div>
            {
                !user ? <SignIn /> : 
                <div className='dashboard_container'>
                    {user.type === "admin" ?
                        <>
                            <NavBar content={content} setContent={setContent} />
                            <div className='content_container'>
                                {
                                    content === "main" ? <AdminMainPage /> :
                                    content === "homeOwnerVerification" ? <HOVContent /> :
                                    content === "realtorVerification" ? <RVContent /> :
                                    content === "activeListings" ? <ALContent /> :
                                    <MapContent />
                                }
                            </div>
                        </> : 
                    user.type === "realtor" ?
                        !user.realtorDetails ? <RealtorOnboarding /> : <RealtorDashboard /> :
                        <div className='center flex_column'>
                            <h1>You do not have access to this dashboard</h1>
                            <a href='/realtor'><button>Become a HINT realtor</button></a>
                            <a href='/'><button>Return home</button></a>
                            <button onClick={() => Auth.signOut()}>Sign Out</button>
                        </div>}
                </div>
            }
        </div>
    );
}

export default Dashboard;