import { API, graphqlOperation } from 'aws-amplify';
import React, { useContext } from 'react';
import { updateUser } from '../../graphql/mutations';
import UserContext from '../../context/UserContext';

function TermsConditions(props) {
    const { user, setUser } = useContext(UserContext);

    const handleAccept = async (e) => {
        await API.graphql(graphqlOperation(updateUser, { input: { id: user.id, tcSigned: true }}));
        setUser({...user, tcSigned: true });
    }
    const handleDecline = async (e) => {
        alert("You must accept the Terms & Conditions to continue with signing up.");
        return;
    }
    return (
        <div className='realtor_form'>
            <p className='bold'>Read our Terms & Conditions</p>
            <p className='helper_text'>Please carefully read our terms & conditions below, we value transparency.</p>
            <p>
            <strong>HINT Terms of Service</strong>
            <br />
            <br />
            Welcome to HINT, your Housing Intelligence portal. By accessing,
            registering, utilizing, or accessing any content provided by us
            through this application you agree to be bound by these Terms of Use
            (“Terms of Use”). <br /><br /><strong>1. Acceptance of Terms</strong><br /> By using our app, you
            agree to be bound by these terms of use. If you do not agree with any
            part of these terms, please refrain from using the application. Use of
            this application and/or HINT's services is at your own risk. Neither
            HINT nor any of its officers, directors, investors, employees,
            contractors, agents, successors, assigns, affiliates, or content or
            service providers shall be liable for any direct, indirect, punitive,
            incidental, special or consequential damages or any other injury
            arising out of or in any way connected to the use of this Website or
            HINT's services, including also damages arising out of the inability
            to access the application or its services, even if HINT had been
            advised of the possibility of such damages. <br /><br /><strong>2. Registration
            Eligibility</strong> You must be at least 18 years of age to use the Services.
            By agreeing to these Terms of Use, you represent and warrant that:{" "}
            <br />(a) you are at least 18 years of age; <br />(b) you have not
            previously been suspended or removed from the HINT application; and{" "}
            <br />(c) your registration and your use of the services complies with
            all applicable laws and regulations. When you register for an account,
            you will be asked to provide us with some information about yourself,
            such as email address, phone number, or other contact information. You
            agree that the information you provide is accurate and that you will
            keep it accurate and up to date at all times. When you register, you
            will be asked to provide a password. You are solely responsible for
            maintaining the confidentiality of your account and password, and you
            are responsible for all actions taken via your account. You may not
            share your user account(s) with others. You agree that all user
            information is true and correct to the best of your knowledge. You
            agree that financial and property ownership information is true and
            correct. Providing false or incorrect information will result in
            suspension of your account and subject to any local, state, or federal
            laws. <br /><br /><strong>3. User Data Collection</strong> HINT collects certain user data to
            provide you with a personalized experience. This data may include your
            name, contact information, location, and preferences. We may also
            collect data on your interactions with the application such as
            property searches, interests, saved listings and interaction with
            third-party vendors. In addition, we may ask for financial or
            homeownership verification to unlock additional features, facilitate
            interactions, and user experiences. <br /><br /><strong>4. Use of User Data</strong> We use
            the collected user data to improve our app's functionality,
            personalize your experience, and provide relevant real estate
            listings, advertisements, support services and recommendations. We may
            also use the data for research and analysis purposes, but rest assured
            that your personal information will be kept confidential. Please note
            our services are not intended to give you any financial, real estate,
            or advice of any kind. <br /><br /><strong>5. License</strong> When using this app, you may
            generate certain content, including, but not limited to, photos or
            videos taken or created by you using the apps ("Content"). You hereby
            grant to HINT, Inc. a non-exclusive, fully paid and royalty-free,
            worldwide, limited license to use, modify, delete from, add to,
            publicly display or reproduce the Content, including without
            limitation distributing part or all in any media formats through any
            media channels, provided such use, modification, deletion or addition
            does not materially change the Content; except Content not shared
            publicly ("private") will not be distributed, displayed or reproduced
            unless explicitly shared by you in the Website. HINT may, in its sole
            discretion and for any reason, remove and destroy Content created by
            you without notice or warning. <br /><br /><strong>6. Data Security</strong> We take data
            security seriously and have implemented measures to protect your
            information from unauthorized access, loss, or misuse. However, please
            note that no method of transmission over the internet or electronic
            storage is 100% secure, and we cannot guarantee absolute security.{" "}
            <br /><br /><strong>7. Third-Party Services</strong> Our app may integrate with third-party
            services, such as payment gateways, verification portal, real estate
            professionals, mortgage provider, title and escrow services, insurance
            provider, or other settlement services. Please note that these
            services have their own terms of use and privacy policies, which you
            should review separately. You authorize us to make such referral or
            lead sale and acknowledge that we may be paid valuable consideration
            for facilitating such connection. HINT does not guarantee or warrant
            the products or services of other product or service providers to whom
            it may refer you, including, but not limited to, real estate
            brokers/agents, lenders and escrow/settlement companies. <br /><br /><strong>8. User
            Responsibilities</strong> As a user of our application, you are responsible
            for maintaining the confidentiality of your account credentials and
            for any activities that occur under your account. You agree not to use
            the app for any illegal or unauthorized purposes. <br /><br /><strong>9. Changes to
            Terms</strong> We reserve the right to modify these terms of use at any time.
            Any changes will be effective immediately upon posting on our
            application. It is your responsibility to review these terms
            periodically for any updates. <br /><br /><strong>10. Termination</strong> We reserve the right to
            terminate or suspend your access to the HINT application at any time,
            without prior notice or liability, for any reason whatsoever,
            including if you breach these terms. <br /><br /><strong>11. Contact Information</strong> If you
            have any questions or concerns regarding these terms of use, please
            contact us at (864)275-0650.
            </p>
            <button onClick={handleAccept}>Accept</button>
            <button onClick={handleDecline}>Decline</button>
        </div>
    );
}

export default TermsConditions;