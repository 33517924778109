import React, { useEffect, useState } from 'react';
import { listRealtorDetails } from '../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import RVCard from './RVCard';
import { CircularProgress, TablePagination } from '@mui/material';

function RVContent(props) {
    const [loading, setLoading] = useState(false);
    const [rvContent, setRvContent] = useState([]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };

    useEffect(() => {
        setLoading(true);
        (async () => {
            const pendingDetails = await API.graphql(graphqlOperation(listRealtorDetails, { filter: { status: { eq: 'pending'}}}));
            console.log(pendingDetails.data.listRealtorDetails.items);
            setRvContent(pendingDetails.data.listRealtorDetails.items);
            setLoading(false);
        })();
    }, [])

    return (
        <div className='card_container'>
            <h1>Realtor Verification</h1>
            <div className='verification_table'>
                <div className='table_header'>
                    <h3>Name</h3>
                    <h3>Email</h3>
                    <h3>License State</h3>
                    <h3>License Number</h3>
                    <h3>Verify</h3>
                </div>
                {loading ? <div className='center'><CircularProgress /></div> : rvContent.length > 0 ? <div className='card_list_view'>{rvContent.map(content => <RVCard key={content.id} content={content} />)}</div> : <div className='center'><h3>There are currently no pending verifications</h3></div>}
                <div className='pagination_holder'>
                    <TablePagination
                        component="div"
                        count={rvContent.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            </div>
        </div>
    );
}

export default RVContent;