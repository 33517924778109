import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

function AlertCard({ title, text, cancelBtn = "Cancel", submitBtn = "Submit", handleSubmit = null, openState }) {
    return (
        <Dialog open={openState.open} onClose={() => openState.setOpen(false)}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
            <DialogContentText>
                {text} 
            </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => openState.setOpen(false)}>{cancelBtn}</Button>
                <Button onClick={handleSubmit}>{submitBtn}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default AlertCard;