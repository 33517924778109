import React from 'react';

function ALCard({ content }) {
    return (
        <div className='card_holder'>
            <p>{content.addressOne} - {content.zip}</p>
            <p>{content.interestType}</p>
            <p>{content.interestPrice}</p>
            <button>View Photos</button>
            <div>
                <button>Edit</button>
                <button>Hide</button>
            </div>
        </div>
    );
}

export default ALCard;