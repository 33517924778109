import logo from "./logo.svg";
import "./App.css";
import { useEffect, useState } from "react";
import Landing from "./screens/Landing";
import Dashboard from "./screens/Dashboard";
import ErrorPath from "./screens/ErrorPath";
import UserContext from "./context/UserContext";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { API, Amplify, Auth, Hub, graphqlOperation } from "aws-amplify";
import awsExports from "./aws-exports";
import RealtorOnboarding from "./screens/RealtorOnboarding";
import { userByEmail } from "./graphql/queries";
import Layout from "./components/Layout";
Amplify.configure(awsExports);

function App() {
  const [user, setUser] = useState(null);

  // called when app is first started and during auth events (sign in / out)
  const checkUser = async () => {
    try {
      const authUser = await Auth.currentAuthenticatedUser();
      const userDetails = await API.graphql(
        graphqlOperation(userByEmail, { email: authUser.attributes.email })
      );
      console.log(userDetails.data.userByEmail.items[0]);
      setUser(userDetails.data.userByEmail.items[0]);
    } catch (e) {
      console.log(e);
      setUser(null);
    }
  };

  useEffect(() => {
    checkUser();
    const listener = (data) => {
      console.log(data.payload);
      if (data.payload.event === "signIn" || data.payload.event === "signOut") {
        console.log(`${data.payload.event} event just occurred`);
        checkUser();
      }
    };

    Hub.listen("auth", listener);
    return () => Hub.remove("auth", listener);
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Landing />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="realtor" element={<RealtorOnboarding />} />
            <Route path="*" element={<ErrorPath />} />
          </Route>
          <Route path="*" element={<Landing />} />
        </Routes>
      </BrowserRouter>
    </UserContext.Provider>
  );
}

export default App;
